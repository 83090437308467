import { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { registerUser } from './Functions/Authentication-Functions'
import { GoogleLogin } from '@react-oauth/google'
import StandardNavBar from './StandardNavBar'
import jwt_decode from 'jwt-decode'

function Registration(prop) {
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState(null)
    const [googleButtonWidth, setGoogleButtonWidth] = useState(null)

    // the following will highlight the registration button from the navigation bar when this component is mounted
    useEffect(() => {
        const navButton = document.querySelector('.nav-button.register')
        if (navButton !== null && navButton !== undefined) {
            navButton.style.backgroundColor = '#aaa9c1'
        }
    })

    // the following will highlight the borders of the text fields red when the error state gets set 
    useEffect(() => {
        if (error !== null) {
            const NodeList = document.querySelectorAll('.registration input[type="text"]')
            for (let i = 0; i < NodeList.length; i++) {
                Object.assign(NodeList[i].style, {
                    border: "2px solid red",
                    boxShadow: "0 0px 2px red"
                })
            }
        }
    }, [error])
    
    // the following function changes the googleButtonWidth state to match its container
    useEffect(() => {
        // the following defines an observer object that sets the googleButtonWidth 
        // state whenever it observes a resize in the google button container  
        var ro = new ResizeObserver(entries => {
            for (let entry of entries) {
                setGoogleButtonWidth(parseInt(entry.contentRect.width))
            }
        })
        // the following attaches the ro observer object to the container of the google button
        ro.observe(document.getElementsByClassName("google-login-button")[0]);
    }, [])

    // the following function registers the user when they use the system registration form
    function handleSubmit(submitEvent) {
        submitEvent.preventDefault()
        const new_user = { username, email, password }
        registerUser(prop.setAuthorised, new_user, "standard", prop.setAuthorisationIsPending).catch(error => setError(error.message))
    }

    // the following function registers the user when they use the google sign-up button
    function successfulGoogleLogin(response) {
        // the response from the google authnetication page is decoded to retrieve the user credentials 
        const loadedUser = jwt_decode(response.credential)
        // the user credentials are used to register the user with the server application
        registerUser(prop.setAuthorised, {email: loadedUser.email}, "google", prop.setAuthorisationIsPending).catch(error => setError(error.message))
    }

    function failedGoogleLogin(response) {
        console.log(response)
    }

    if (prop.authorised) {
        return (<Navigate to="/home" />)
    }

    return (
        <div className="registration">
            <StandardNavBar authorised={prop.authorised} />
            <div className="content">
                <div className="page-title">Sign Up</div>
                {error && <div className="error-message">{error}</div>}
                <form onSubmit={handleSubmit}>
                    <div className="username-field">
                        <input
                            type="text"
                            required
                            placeholder="Username"
                            value={username}
                            onChange={(event) => setUsername(event.target.value)}
                        />
                    </div>
                    <div className="email-address-field">
                        <input
                            type="text"
                            required
                            placeholder="Email Address"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                    </div>
                    <div className="password-field">
                        <input
                            type="text"
                            required
                            placeholder="Password"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                        />
                    </div>
                    <button className="submit">Create User</button>
                </form>
                <div className="google-login-button">
                    <GoogleLogin
                        onSuccess={successfulGoogleLogin}
                        onError={failedGoogleLogin}
                        text="signup_with"
                        width={googleButtonWidth}
                    />
                </div>
            </div>

        </div>
    )
}
export default Registration