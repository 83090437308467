import { fetchRecipesFromIdList } from './Fetch-Functions'
import { response_handler } from './Fetch-Functions'

// the following function retrieves the list of recipe IDs that have been saved by the current user
export async function loadSavedRecipeIDs(setSavedRecipeIDs) {
    const response = await fetch(process.env.REACT_APP_backEndServerURL + "/retrieve-saved-recipes", {credentials: "include"})
    const data = await response_handler(response)
    const recipeIDs = data["saved-recipes"]
    // the function sets the savedRecipeIDs state using the retrieved list
    setSavedRecipeIDs(recipeIDs)
    return recipeIDs
}

// the following function retrieves recipe information for all recipe IDs from the savedRecipeIDs state
export async function loadSavedRecipes(savedRecipeIDs, setSavedRecipes) {
    const recipes = await fetchRecipesFromIdList(savedRecipeIDs)
    setSavedRecipes(recipes)
    return recipes
}

// the following function loads the saved recipes of the current user
export async function loadUser(setSavedRecipeIDs, setSavedRecipes, setSavedRecipeIDsIsPending) {
    // the saved recipe IDs are retrieved and the corresponding client state is set
    const savedRecipeIDs = await loadSavedRecipeIDs(setSavedRecipeIDs)
    setSavedRecipeIDsIsPending(false)
    // the saved recipe information are retrieved and the corresponding client state is set
    const recipes = await loadSavedRecipes(savedRecipeIDs, setSavedRecipes)
    return { savedRecipeIDs, recipes }
}

