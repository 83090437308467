import { useState, useEffect } from 'react'
import { Navigate, Link } from 'react-router-dom'
import { loginUser } from './Functions/Authentication-Functions'
import { GoogleLogin } from '@react-oauth/google'
import StandardNavBar from './StandardNavBar'
import jwt_decode from 'jwt-decode'

function Login(prop) {
    const [username_or_email, setUsername_or_email] = useState("")
    const [password, setPassword] = useState("")
    const [remember_me, setRemember_me] = useState(false)
    const [error, setError] = useState(null)
    const [googleButtonWidth, setGoogleButtonWidth] = useState(null)

    // the following will highlight the borders of the text fields red when the error state gets set 
    useEffect(() => {
        if (error !== null) {
            const NodeList = document.querySelectorAll('.login input[type="text"]')
            for (let i = 0; i < NodeList.length; i++) {
                Object.assign(NodeList[i].style, {
                    border: "2px solid red",
                    boxShadow: "0 0px 2px red"
                })
            }
        }
    }, [error])

    // the following function changes the googleButtonWidth state to match its container
    useEffect(() => {
        // the following defines an observer object that sets the googleButtonWidth 
        // state whenever it observes a resize in the google button container  
        var ro = new ResizeObserver(entries => {
            for (let entry of entries) {
                setGoogleButtonWidth(parseInt(entry.contentRect.width))
            }
        })
        // the following attaches the ro observer object to the container of the google button
        ro.observe(document.getElementsByClassName("google-login-button")[0]);
    }, [])

    // the following will highlight the login button from the navigation bar when this component is mounted
    useEffect(() => {
        const navButton = document.querySelector('.nav-button.login')
        if (navButton !== null && navButton !== undefined) {
            navButton.style.backgroundColor = '#aaa9c1'
        }
    }, [])

    // the following function logs the user in when they use the system login form
    function handleStandardSubmit(submitEvent) {
        submitEvent.preventDefault()
        const userCredentials = { username_or_email, password, remember_me }
        loginUser(prop.setAuthorised, userCredentials, "standard", prop.setAuthorisationIsPending).catch(error => setError(error.message))
    }

    // the following function logs the user in when they use the google login button
    function successfulGoogleLogin(response) {
        // the response from the google authnetication page is decoded to retrieve the user credentials 
        const userCredentials = jwt_decode(response.credential)
        // the user credentials are used to log the user in with the server application
        loginUser(prop.setAuthorised, {email: userCredentials.email}, "google", prop.setAuthorisationIsPending).catch(error => setError(error.message))
    }

    function failedGoogleLogin(response) {
        console.log(response)
    }

    if (prop.authorised) {
        return (<Navigate to="/home" />)
    }

    return (
        <div className="login">
            <StandardNavBar authorised={prop.authorised} />
            <div className="content">
                <div className="page-title">Sign In</div>
                <form onSubmit={handleStandardSubmit}>
                    <div className="username-email-field">
                        <input
                            type="text"
                            required
                            value={username_or_email}
                            placeholder="Username or Email"
                            onChange={(event) => setUsername_or_email(event.target.value)}
                        />
                        {error && <div className="error-message">{error}</div>}
                    </div>
                    <div className="password-field">
                        <input
                            type="text"
                            required
                            value={password}
                            placeholder="Password"
                            onChange={(event) => setPassword(event.target.value)}
                        />
                        {error && <div className="error-message">{error}</div>}
                    </div>
                    <button className="submit">Login</button>
                    <div className="additional-options-field">
                        <div className="remember-me-field">
                            <div className="title">Remember Me</div>
                            <input
                                type="checkbox"
                                value={remember_me}
                                onChange={(event) => setRemember_me(event.target.checked)}
                            />
                        </div>
                        <Link className='redirect-link' to="/register">Don't have an account?</Link>
                        <Link className='redirect-link' to="/home">Forgot your password?</Link>
                    </div>
                </form>
                <div className="google-login-button">
                    {googleButtonWidth > 160 ?
                        <GoogleLogin
                            onSuccess={successfulGoogleLogin}
                            onError={failedGoogleLogin}
                            text= "singup_with"
                            type="standard"
                            width={googleButtonWidth}
                        /> :
                        <GoogleLogin
                            onSuccess={successfulGoogleLogin}
                            onError={failedGoogleLogin}
                            text= "singup_with"
                            type="standard"
                            size="small"
                            width={googleButtonWidth}
                        />
                    }
                </div>
            </div>
        </div>
    )
}
export default Login;