import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { fetchRandomRecipes, fetchQueryRecipes, fetchRecommendedRecipes, fetchPersonalisedQueryRecipes } from './Functions/Fetch-Functions'
import { useLocation, useNavigate } from "react-router-dom"
import { SkeletonTheme } from 'react-loading-skeleton'
import missingImage from './meal-image.png';
import SaveButton from './SaveButton'
import SearchNavBar from './SearchNavBar'
import FilterSearchBar from './FilterSearchBar'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import _ from 'lodash'

function RecipeSearch(prop) {
    const [currentPageRecipes, setCurrentPageRecipes] = useState(null)
    const [error, setError] = useState(null)
    const [queryTerm, setQueryTerm] = useState("")
    const [appliedFilters, setAppliedFilters] = useState({ ingredients: [], cuisines: [], cookTime: 100000, nutritionalValues: { maxCalorie: 100000, minProtein: 0, maxCarbs: 100000, maxFat: 100000 } })
    const [recipeFetchIsPending, setRecipeFetchIsPending] = useState(true)
    const pageRecipeCount = 10
    const location = useLocation()
    const navigate = useNavigate()

    // the following hook populates the home page when this component is mounted (or when the authorisationIsPending state changes)
    useEffect(() => {
        if (!prop.authorisationIsPending) {
            // the hook checks to see if the location object carries a state property
            // the state proeprty is set to be populated only by the recipe detail page when the user performs a filtered recipe search 
            if (location.state !== null && location.state !== undefined) {
                // the hook uses the content of the state property to carry out a filtered recipe search
                setQueryTerm(location.state.queryTerm)
                setAppliedFilters(location.state.appliedFilters)
                setPageRecipes(location.state.queryTerm, location.state.appliedFilters)
            } else {
                // if the state property is null, then there was no filtered recipe search performed from the recipe detail page
                setPageRecipes(queryTerm, appliedFilters)
            }
        }
    }, [prop.authorisationIsPending])

    // the following will highlight the home button from the navigation bar when this component is mounted
    useEffect(() => {
        const navButton = document.querySelector('.nav-button.home')
        if (navButton !== null && navButton !== undefined) {
            navButton.style.backgroundColor = '#aaa9c1'
        }
    }, [])

    // the following function will reset the state property of the location object 
    function handlePageRefresh() {
        if (location.state !== null && location.state !== undefined) {
            const perf = window.performance;
            // the function checks to see if the most recent navigation to the current page was of type "reload"
            if (perf && perf.getEntriesByType("navigation")[0].type === "reload") {
                // the state property of the location object is nulled
                const updatedLocation = { ...location, state: null };
                // the applicaiton navigates to the updated location which carries an empty state property
                // this will refresh the home page as the applied filters that were attached to the location object have been flushed out
                navigate(updatedLocation, { replace: true });
            }
        }
    }

    // the following hook will run the handlePageRefresh function before the page is refreshed
    useEffect(() => {
        window.addEventListener('beforeunload', handlePageRefresh)
        return () => { window.removeEventListener('beforeunload', handlePageRefresh) }
    }, [navigate])

    // the following function fetches random recipes and sets the home page recipes
    async function setRandomRecipes() {
        // random recipes are fetched from the server applicaiton
        const response = await fetchRandomRecipes(pageRecipeCount).catch(errorObject => setError(errorObject.message))
        setRecipeFetchIsPending(false)
        console.log("random", response)
        if (response !== undefined) {
            setCurrentPageRecipes(response)
        }
    }

    // the following function fetches recommended recipes and sets the home page recipes
    async function setRecommendedRecipes() {
        // recommended recipes are fetched from the server applicaiton
        const response = await fetchRecommendedRecipes(pageRecipeCount).catch(errorObject => setError(errorObject.message))
        setRecipeFetchIsPending(false)
        console.log("recommended: ", response)
        if (response !== undefined) {
            setCurrentPageRecipes(response)
        }
    }

    // the following function fetches personalised filtered recipes and sets the home page recipe
    async function setPersonalisedQueryRecipes(queryTerm, appliedFilters) {
        // personalised filtered recipes are fetched from the server applicaiton
        const response = await fetchPersonalisedQueryRecipes(pageRecipeCount, queryTerm, appliedFilters).catch(errorObject => setError(errorObject.message))
        setRecipeFetchIsPending(false)
        console.log("personalised query: ", response)
        if (response !== undefined) {
            setCurrentPageRecipes(response)
        }
    }

    // the following function fetches filtered recipes and sets the home page recipe
    async function setQueryRecipes(queryTerm, appliedFilters) {
        // filtered recipes are fetched from the server applicaiton
        const response = await fetchQueryRecipes(pageRecipeCount, queryTerm, appliedFilters).catch(errorObject => setError(errorObject.message))
        setRecipeFetchIsPending(false)
        console.log("query: ", response)
        if (response !== undefined) {
            setCurrentPageRecipes(response)
        }
    }

    // the following function sets the current page recipes in accordance to the user authentication status and the recipe 
    // search filter values
    async function setPageRecipes(queryTerm, appliedFilters) {
        setRecipeFetchIsPending(true)
        // the application will provide personalised recipe recommendations if the user is 
        // authenticated (and the application is using its own database implemented to support this feature)
        if (prop.authorised && process.env.REACT_APP_databaseToContact === "TheCookBook") {
            // unfiltered recipes are fetched if there is no query term or filter values
            if (queryTerm === "" && appliedFilters.ingredients.length === 0 && appliedFilters.cuisines.length === 0 && appliedFilters.cookTime === 100000 && _.isEqual(appliedFilters.nutritionalValues, { maxCalorie: 100000, minProtein: 0, maxCarbs: 100000, maxFat: 100000 })) {
                setRecommendedRecipes()
            } else {
                setPersonalisedQueryRecipes(queryTerm, appliedFilters)
            }
        } else {
            // unfiltered recipes are fetched if there is no query term or filter values
            if (queryTerm === "" && appliedFilters.ingredients.length === 0 && appliedFilters.cuisines.length === 0 && appliedFilters.cookTime === 100000 && _.isEqual(appliedFilters.nutritionalValues, { maxCalorie: 100000, minProtein: 0, maxCarbs: 100000, maxFat: 100000 })) {
                setRandomRecipes()
            } else {
                setQueryRecipes(queryTerm, appliedFilters)
            }
        }
    }

    // the following function will set the page recipes whenever the form containing the "search by recipe" field is submitted
    async function handleRecipeSearchSubmit(submitEvent) {
        submitEvent.preventDefault()
        setPageRecipes(queryTerm, appliedFilters)
    }

    function SearchResults(recipeFetchIsPending) {
        // this function will render a recipe image if one exists
        // this function will render a place holder image if a recipe image does not exist 
        function renderImage(element) {
            if (element.image !== undefined) {
                return (<img className="image" src={element.image} />)
            } else {
                return (<img className="image" src={missingImage} />)
            }
        }

        if (recipeFetchIsPending === false) {
            if (currentPageRecipes !== null) {
                if (currentPageRecipes.length !== 0) {
                    return (
                        <div>
                            <div className="search-results">
                                {currentPageRecipes.map((element) =>
                                    <div className="search-result" key={element.id}>
                                        <Link to={"/recipes/" + element.id}>
                                            {renderImage(element)}
                                            <div className="text-container">
                                                <div className="title">{element.title}</div>
                                                <div className="cook-time">Cook Time: {element.readyInMinutes}</div>
                                                <div className="ingredient-count">Ingredient Count: {element.extendedIngredients.length}</div>
                                            </div>
                                            <SaveButton
                                                useIsAuthenticated={prop.authorised}
                                                savedRecipeIDs={prop.savedRecipeIDs}
                                                setSavedRecipeIDs={prop.setSavedRecipeIDs}
                                                savedRecipes={prop.savedRecipes}
                                                setSavedRecipes={prop.setSavedRecipes}
                                                recipe_id={element.id}
                                            />
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div className="no-recipes-message">No Recipes Found!</div>
                    )
                }
            }
        } else {
            return (
                <div className="search-results">
                    {[...Array(pageRecipeCount)].map((element, index) =>
                        <SkeletonTheme baseColor='#6A6767' highlightColor='#525252' key={index}>
                            <div className="temporary-search-result">
                                <div className="image"><Skeleton></Skeleton></div>
                                <div className="text-container">
                                    <div className="title"><Skeleton></Skeleton></div>
                                    <div className="cook-time"><Skeleton></Skeleton></div>
                                    <div className="ingredient-count"><Skeleton></Skeleton></div>
                                </div>
                            </div>
                        </SkeletonTheme>
                    )}
                </div>
            )
        }
    }

    return (
        <div className="recipe-search">
            <SearchNavBar
                queryTerm={queryTerm}
                setUserIsAuthenticated={prop.setUserIsAuthenticated}
                setQueryTerm={setQueryTerm}
                handleRecipeSearchSubmit={handleRecipeSearchSubmit}
                authorised={prop.authorised}
                setSavedRecipeIDs={prop.setSavedRecipeIDs}
                setRecommendedRecipes={setRecommendedRecipes}
                setRandomRecipes={setRandomRecipes}
                setSavedRecipes={prop.setSavedRecipes}
                setAppliedFilters={setAppliedFilters}
            />
            <FilterSearchBar
                appliedFilters={appliedFilters}
                setAppliedFilters={setAppliedFilters}
            />
            {error && <div className="error">{error}</div>}
            <div className="recipe-results-contianer">
                {SearchResults(recipeFetchIsPending)}
            </div>
        </div>
    )
}
export default RecipeSearch
