import { postRquest, response_handler } from './Fetch-Functions'
import { googleLogout } from '@react-oauth/google'

// the following function contacts the server application to log the user in
export async function loginUser(setAuthorised, userCredentials, loginType, setAuthorisationIsPending){
    // the user authentication is set to be pending 
    setAuthorisationIsPending(true)
    let response = null
    if (loginType === "standard"){
        response = await postRquest("/login", userCredentials)
    } 
    if (loginType === "google"){
        response = await postRquest("/google-login", userCredentials)
    }  
    // the user authentication is set to no longer be pending 
    setAuthorisationIsPending(false)
    const responseData = await response_handler(response)
    if (responseData.error === undefined) {
        // the user authentication state is set to true if the received server HTTP response does not display an error
        setAuthorised(true)
    } else {
        throw new Error(responseData.error)
    }   
}

// the following function contacts the server application to register the user 
export async function registerUser(setAuthorised, new_user, registerationType, setAuthorisationIsPending){
    // the user authentication is set to be pending 
    setAuthorisationIsPending(true)
    let response = null
    if (registerationType === "standard"){
        response = await postRquest("/register", new_user)
    } else if (registerationType === "google"){
        response = await postRquest("/google-register", new_user)
    }
    // the user authentication is set to no longer be pending 
    setAuthorisationIsPending(false)
    const responseData = await response_handler(response)
    if (responseData.error === undefined) {
        // the user authentication state is set to true if the received server HTTP response does not display an error
        setAuthorised(true)
    } else {throw new Error(responseData.error)}   
}

// the following function contacts the server to check the user's authentication status
export async function authenticateUser(setAuthorised, setAuthorisationIsPending){
    const response = await fetch(process.env.REACT_APP_backEndServerURL + "/authenticate-user", {credentials: "include"})
    const data = await response_handler(response)
    const isAuthenticated = data.token
    // the user authentication state is set equal to authentication token received from the server
    setAuthorised(isAuthenticated)
    setAuthorisationIsPending(false)
    return isAuthenticated
}

// the following function contacts the server to log the user out
export async function logout(navigate, setUserIsAuthenticated, setSavedRecipeIDs, setSavedRecipes){
    fetch(process.env.REACT_APP_backEndServerURL + "/logout", {credentials: "include"}).then((response) => {
        if (response.ok) {
            googleLogout()
            // the user authentication state is set to false
            setUserIsAuthenticated(false)
            // the user saved recipes are unloaded
            setSavedRecipeIDs([])
            setSavedRecipes([])
            navigate('/home')
        } 
    })
}


