import { heart } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import { handleSaveButtonClick } from './Functions/Save-Recipe-Functions';

function SaveButton({ useIsAuthenticated, savedRecipeIDs, setSavedRecipeIDs, savedRecipes, setSavedRecipes, recipe_id }) {
    // the following function renders the saveIcon that corresponds to the recipe whose ID equals the recipe_id parameter 
    function saveIcon() {
        if (useIsAuthenticated) {
            if (savedRecipeIDs !== null) {
                if (savedRecipeIDs.includes(recipe_id)) {
                    // the active class will prompt css rules for the save icon and highlight it
                    return (
                        <IonIcon className="heart-icon active" id={recipe_id} icon={heart} />
                    )
                }
            }
        }
        return (
            <IonIcon className="heart-icon" id={recipe_id} icon={heart} />
        )
    }

    return (
        <div onClick={(event) => {
                event.stopPropagation()
                event.preventDefault()
                handleSaveButtonClick(useIsAuthenticated, savedRecipeIDs, setSavedRecipeIDs, savedRecipes, setSavedRecipes, recipe_id)
            }} 
            className="save-button"
        >
            {saveIcon()}
        </div>
    )
}

export default SaveButton