import { useParams, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { fetchRecipeInformation } from "./Functions/Fetch-Functions"
import { SkeletonTheme } from "react-loading-skeleton"
import SearchNavBar from "./SearchNavBar"
import FilterSearchBar from "./FilterSearchBar"
import Skeleton from "react-loading-skeleton"
import SaveButton from "./SaveButton"

function RecipeDetails(prop) {
    const [currentPageRecipe, setCurrentPageRecipe] = useState(null)
    const [error, setError] = useState(null)
    const [queryTerm, setQueryTerm] = useState("")
    const [appliedFilters, setAppliedFilters] = useState({ ingredients: [], cuisines: [], cookTime: 100000, nutritionalValues: { maxCalorie: 100000, minProtein: 0, maxCarbs: 100000, maxFat: 100000 } })
    const [isPending, setIsPending] = useState(true)
    const { id } = useParams()
    const navigate = useNavigate()

    // the following hook populates the recipe detail page with recipe information when the page is mounted
    useEffect(() => {
        async function setRecipeInformation() {
            // the hook contacts the server to fetch recipe information
            const response = await fetchRecipeInformation(id).catch(errorObject => setError(errorObject.message))
            setIsPending(false)
            setCurrentPageRecipe(response)
        }
        setRecipeInformation()
    }, [id])

    // the following function formats the recipe cuisine types into easily readable text 
    function recipeCuisines() {
        if (currentPageRecipe.cuisines.length === 0) {
            return <span className="cuisine"> -</span>
        } else if (currentPageRecipe.cuisines.length === 1) {
            return <span className="cuisine"> {currentPageRecipe.cuisines[0]}</span>
        } else {
            return currentPageRecipe.cuisines.map((cuisine, index) => {
                if (index === currentPageRecipe.cuisines.length - 1) {
                    return <span key={'cuisine-' + index} className="cuisine">{cuisine}</span>
                } else {
                    return <span key={'cuisine-' + index} className="cuisine">{cuisine}, </span>
                }
            })
        }
    }

    // the following function identifies the dominating taste of the recipe, reformats its name, and outputs it to be displayed 
    function recipeTaste() {
        let dominatingTaste = null
        const recipeTastes = Object.assign({}, currentPageRecipe.taste)
        for (let taste in recipeTastes) {
            if (dominatingTaste === null) {
                dominatingTaste = taste
            }
            if (recipeTastes[taste] > recipeTastes[dominatingTaste]) {
                dominatingTaste = taste
            }
        }
        let output
        switch (dominatingTaste) {
            case "bitterness":
                output = "bitter"
                break
            case "fattiness":
                output = "fatty"
                break
            case "saltiness":
                output = "salty"
                break
            case "savoriness":
                output = "savory"
                break
            case "sourness":
                output = "sour"
                break
            case "spiciness":
                output = "spicy"
                break
            case "sweetness":
                output = "sweet"
                break
        }
        return output
    }

    // the following function populates the recipe instructions segment of the recipe detail page
    function recipeInstructions() {
        if (currentPageRecipe.analyzedInstructions.length === 0) {
            return <div>Instructions Missing!</div>
        // the following code segment will run if the instructions consist of one task
        } else if (currentPageRecipe.analyzedInstructions.length === 1) {
            const task = currentPageRecipe.analyzedInstructions[0]
            return (
                <div className="instruction-task">
                    <div className="task-name">{task.name}</div>
                    <div className="task-steps single-task">
                        {task.steps.map(stepInstruction =>
                            // the function splits every step (as indicated by the retrieved recipe information) into further steps  
                            // this is a quality control step as some steps occasionally consist of multiple steps
                            // the steps are split at every "." character which is preceded or followed by additional text 
                            stepInstruction.step.split(/(?<!\d)\.(?!\d)\s*/)
                                .filter(stepInstruction => stepInstruction !== '')
                                .map((detailedInstructions, stepIndex) => <div className="step" key={stepIndex}><span className="bullet-point">&bull;</span>&nbsp;&nbsp;{detailedInstructions}</div>)
                        )}
                    </div>
                </div>
            )
        // the following code segment will run if the instructions consists of multiple tasks
        } else {
            return (
                currentPageRecipe.analyzedInstructions.map((task, taskIndex) =>
                    <div className="instruction-task" key={taskIndex}>
                        <div className="task-name">{task.name}</div>
                        <div className="task-steps multi-task">
                            {task.steps.map(stepInstructions =>
                                stepInstructions.step.split(".")
                                    .slice(0, -1).map((detailedInstructions, stepIndex) => <div className="step" key={taskIndex * 100 + stepIndex}><span className="bullet-point">&bull;</span>&nbsp;&nbsp;{detailedInstructions}</div>))}
                        </div>
                    </div>
                )
            )
        }
    }

    // the following function is executed whenever the user performs a filtered recipe search from the recipe detail page
    async function handleRecipeSearchSubmit(submitEvent) {
        submitEvent.preventDefault()
        // the application redirects to the home page where the filtered recipe search results will be displayed
        navigate('/home', {
            // the function appends a state property to the location object to convey the filter values to the home page 
            state: {
                queryTerm: queryTerm,
                appliedFilters: appliedFilters
            }
        })
    }

    return (
        <div className="recipe-details">
            <SearchNavBar queryTerm={queryTerm} setQueryTerm={setQueryTerm} handleRecipeSearchSubmit={handleRecipeSearchSubmit} authorised={prop.authorised}></SearchNavBar>
            <FilterSearchBar appliedFilters={appliedFilters} setAppliedFilters={setAppliedFilters} handleRecipeSearchSubmit={handleRecipeSearchSubmit}></FilterSearchBar>
            {error && <div className="error">{error}</div>}
            {isPending === false && currentPageRecipe !== null && prop.savedRecipeIDs !== null &&
                <div className="content">
                    <div className="basic-information">
                        <div className="image-container">
                            <img className="image" src={currentPageRecipe.image} alt="" />
                        </div>
                        <div className="text-container">
                            <div className="title">{currentPageRecipe.title}</div>
                            <div className="cook-time">Cook time: {currentPageRecipe.readyInMinutes}</div>
                            <div className="servings">Servings: {currentPageRecipe.servings}</div>
                            <div className="cuisines">Cuisines: {recipeCuisines()}</div>
                            <div>Tastes: {recipeTaste()}</div>
                        </div>
                        <div className="save-button recipe-details">
                            <SaveButton
                                useIsAuthenticated={prop.authorised}
                                savedRecipeIDs={prop.savedRecipeIDs}
                                setSavedRecipeIDs={prop.setSavedRecipeIDs}
                                savedRecipes={prop.savedRecipes}
                                setSavedRecipes={prop.setSavedRecipes}
                                recipe_id={currentPageRecipe.id}
                            />

                        </div>
                        <div className="summary" dangerouslySetInnerHTML={{ __html: currentPageRecipe.summary }}></div>
                    </div>
                    <hr />
                    <div className="main-body">
                        <div className="ingredient-list">
                            <div className="title">Ingredients:</div>
                            <div className="ingredients-container">
                                {currentPageRecipe.extendedIngredients.map((ingredient, index) =>
                                    <div className="ingredient" key={"ingredient-" + index}>
                                        {ingredient.measures.us.amount + " " + ingredient.measures.us.unitLong + " of " + ingredient.name}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="instructions-list">
                            <div className="title">Instructions: </div>
                            {recipeInstructions()}
                        </div>
                    </div>
                </div>
            }
            {isPending === true &&
                <SkeletonTheme baseColor='#6A6767' highlightColor='#525252'>
                    <div className="content">
                        <div className="basic-information">
                            <div className="image-container skeleton">
                                <Skeleton></Skeleton>
                            </div>
                            <div className="text-container skeleton">
                                <div className="title skeleton"><Skeleton></Skeleton></div>
                                <div className="cook-time skeleton"><Skeleton></Skeleton></div>
                                <div className="servings skeleton"><Skeleton></Skeleton></div>
                            </div>
                            <div className="summary skeleton"><Skeleton></Skeleton></div>
                        </div>
                        <div className="main-body">
                            <div className="ingredient-list skeleton"><Skeleton></Skeleton></div>
                            <div className="instructions-list">
                                <div className="title skeleton"><Skeleton></Skeleton></div>
                                <div className="instruction-tasks skeleton">
                                    <div className="task-1 skeleton"><Skeleton></Skeleton></div>
                                    <div className="task-2 skeleton"><Skeleton></Skeleton></div>
                                    <div className="task-3 skeleton"><Skeleton></Skeleton></div>
                                    <div className="task-4 skeleton"><Skeleton></Skeleton></div>
                                    <div className="task-5 skeleton"><Skeleton></Skeleton></div>
                                    <div className="task-6 skeleton"><Skeleton></Skeleton></div>
                                    <div className="task-7 skeleton"><Skeleton></Skeleton></div>
                                    <div className="task-8 skeleton"><Skeleton></Skeleton></div>
                                    <div className="task-9 skeleton"><Skeleton></Skeleton></div>
                                    <div className="task-10 skeleton"><Skeleton></Skeleton></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SkeletonTheme>
            }
        </div>
    )
}

export default RecipeDetails