function BottomNavBar() {
    return (
        <div className="bottom-nav-bar">
            <div className="content">
                <div className="legal">
                    <div>Terms of service</div>
                    <div>Privacy policy</div>
                </div>
                <div className="information">
                    <div>Contact Us</div>
                    <div>About Us</div>
                    <div>FAQ</div>
                </div>
            </div>
        </div >
    )
}
export default BottomNavBar