import SearchBar from "./SearchBar"
import { Link } from "react-router-dom"
import { useLocation, useNavigate } from "react-router-dom"
import { logout } from "./Functions/Authentication-Functions"

function SearchNavBar(prop) {
    const location = useLocation()
    const navigate = useNavigate()

    // the following funciton is designed to refresh the recipe fetch on the home page 
    function handleHomeClick() {
        if (location.pathname === "/home" || location.pathname === "/") {
            if (prop.authorised && process.env.REACT_APP_databaseToContact === "TheCookBook") {
                // the function fetched recommended recipes for the home page 
                prop.setRecommendedRecipes()
            } else {
                // the function fetched random recipes for the home page 
                prop.setRandomRecipes()
            }
            // the function resets the applied filters to the recipe search 
            prop.setAppliedFilters({ ingredients: [], cuisines: [], cookTime: 100000, nutritionalValues: { maxCalorie: 100000, minProtein: 0, maxCarbs: 100000, maxFat: 100000 } })
        }
    }

    if (prop.authorised) {
        return (
            <nav className="nav-bar">
                <div className="nav-bar-container">
                    <div className="nav-button home" onClick={handleHomeClick}>
                        <Link to="/home" className="">Home</Link>
                    </div>
                    <div className="nav-button meal-planner">
                        <Link to="/home" className="">Meal Planner</Link>
                    </div>
                    <SearchBar 
                        queryTerm={prop.queryTerm} 
                        setQueryTerm={prop.setQueryTerm} 
                        handleRecipeSearchSubmit={prop.handleRecipeSearchSubmit} 
                    />
                    <div className="nav-button saved-recipes">
                        <Link to="/saved-recipes" className="">Saved Recipes</Link>
                    </div>
                    <div className="nav-button logout" onClick={() => logout(navigate, prop.setUserIsAuthenticated, prop.setSavedRecipeIDs, prop.setSavedRecipes)}>
                        <div className="text">Logout</div>
                    </div>
                    <div className="nav-button user-profile">
                        <Link to="/user-profile" className=""></Link>
                    </div>
                </div>
            </nav>
        )
    } else {
        return (
            <nav className="nav-bar">
                <div className="nav-bar-container">
                    <div className="nav-button home">
                        <Link to="/home" className="">Home</Link>
                    </div>
                    <SearchBar 
                        queryTerm={prop.queryTerm} 
                        setQueryTerm={prop.setQueryTerm} 
                        handleRecipeSearchSubmit={prop.handleRecipeSearchSubmit} 
                    />
                    <div className="nav-button register">
                        <Link to="/register" className="">Register</Link>
                    </div>
                    <div className="nav-button login">
                        <Link to="/login" className="">Login</Link>
                    </div>
                </div>
            </nav>
        )
    }
}
export default SearchNavBar