import _ from 'lodash'

// the following function adds a specified filter value to the appliedFilters state
export function addFilter(appliedFilters, setAppliedFilters, filterType, filterValue) {
    const appliedFilters_temp = Object.assign({}, appliedFilters)
    if (filterType === "cookTime") {
        // the filterValue is set as the value of the cookTime property of the appliedFilters object
        appliedFilters_temp[filterType] = filterValue
        setAppliedFilters(appliedFilters_temp)
    } else if (filterType === "nutritionalValues") {
        // the filterValue is set as the value of the nutritionalValues property of the appliedFilters object
        const nutritioalValuesFilter_temp = Object.assign({}, appliedFilters_temp[filterType])
        for (let property in appliedFilters_temp[filterType]) {
            if (property in filterValue) {
                nutritioalValuesFilter_temp[property] = filterValue[property]
            }
        }
        appliedFilters_temp[filterType] = nutritioalValuesFilter_temp
        setAppliedFilters(appliedFilters_temp)
    } else {
        // the filterValue is added to the list of filter values
        appliedFilters_temp[filterType].push(filterValue)
        setAppliedFilters(appliedFilters_temp)
    }
}

// the following function removes a specified filter value to the list of applied filters
export function removeFilter(appliedFilters, setAppliedFilters, filterType, filterValue) {
    const appliedFilters_temp = Object.assign({}, appliedFilters)
    // calculate the index of the filter value within the list of filter values (this applies to ingredient and cuisine filter types)
    const index = appliedFilters_temp[filterType].indexOf(filterValue)
    if (index > -1) {
        // remove the filter value at the calculated index value 
        appliedFilters_temp[filterType].splice(index, 1);
        setAppliedFilters(appliedFilters_temp)
    } else {
        appliedFilters_temp[filterType] = 10000
        setAppliedFilters(appliedFilters_temp)
    }
}

export function handleNutritionalValueChange(nutritionalValues, setNutritionalValues, appliedFilters, setAppliedFilters, valueType, newValue) {
    const nutritionalValues_temp = Object.assign({}, nutritionalValues)
    nutritionalValues_temp[valueType] = newValue
    setNutritionalValues(nutritionalValues_temp)
    if (newValue === "" || newValue === NaN || newValue < 0) {
        if (valueType.includes("max")) {
            addFilter(appliedFilters, setAppliedFilters, "nutritionalValues", { [valueType]: 100000 })
        } else {
            addFilter(appliedFilters, setAppliedFilters, "nutritionalValues", { [valueType]: 0 })
        }
    } else {
        addFilter(appliedFilters, setAppliedFilters, "nutritionalValues", { [valueType]: parseInt(newValue) })
    }
}

// the following function receives a visibility object and applies it to the client state
export function handleFilterVisibility(filterVisiblity, setFilterVisiblity, visibilityObject) {
    const properties = Object.keys(visibilityObject)
    const temp = Object.assign({}, filterVisiblity)
    // the visibilityObject is iterated and its property values replace those  of the filterVisiblity object state
    // note that the properties in the visibilityObject and the filterVisiblity objects have the same names
    for (let index = 0; index < properties.length; index++) {
        const property = properties[index]
        temp[property] = visibilityObject[property]
    }
    setFilterVisiblity(temp)
}

// this function counts the number of filters applied from each filter type
export function filterCounter(appliedFilters, filterType) {
    if (filterType === "cuisines" || filterType === "ingredients") {
        // the number of cuisine or ingredient filter values is equal to the length of their corresponding filter lists 
        const count = appliedFilters[filterType].length
        if (count !== 0) {
            return (<div className="counter">{count}</div>)
        }
    }
    if (filterType === "nutritionalValues") {
        // the nnumber of filter values counted under the nutritional values filter type is incremented by 1
        // for every filter value that deviates from its default value
        const nutritionalValueFilters = appliedFilters.nutritionalValues
        let count = 0 
        if (nutritionalValueFilters.maxCalorie !== 100000){
            count++
        } 
        if (nutritionalValueFilters.minProtein !== 0){
            count++
        }
        if (nutritionalValueFilters.maxFat !== 100000){
            count++
        }
        if (nutritionalValueFilters.maxCarbs !== 100000){
            count++
        }
        if (count !== 0) {
            return (<div className="counter">{count}</div>)
        }
    }
}

// the following function checks the appliedFilters state with the default filter values to determine whether any filters are applied
export function areFiltersApplied(appliedFilters){
    return(appliedFilters.ingredients.length !== 0 
        || appliedFilters.cuisines.length !== 0 
        || appliedFilters.cookTime !== 100000 
        || !_.isEqual(appliedFilters.nutritionalValues, { maxCalorie: 100000, minProtein: 0, maxCarbs: 100000, maxFat: 100000 })
    )
}

// the following function determines whether an input value is from a specified data repository
export function isValidInput(inputValue, dataRepository) {
    const exactMatch = dataRepository.filter((item) => { return inputValue.toLowerCase() === item.name.toLowerCase() })
    if (exactMatch.length === 1) {
        return [true, exactMatch]
    }
    return [false, exactMatch]
}

// the following function filters out every data value in a data repository that does not contain a specified search term
export function autocomplete(inputValue, dataRepository, appliedFilters, filterType) {
    const temp = Object.assign({}, appliedFilters)
    const filteredValues = temp[filterType]
    const filteredDataRepository = dataRepository.filter((element) => !filteredValues.includes(element.name))
    if (inputValue !== "") {
        // the search term is defined as the lower case format of the input value
        const searchTerm = inputValue.toLowerCase()
        // the function checks to see if the search term matches any elements of the data repository exactly
        const exactMatch = filteredDataRepository.filter((item) => { return searchTerm === item.name.toLowerCase() })
        // the function returns only the exact match if one is found
        if (exactMatch.length === 1) {
            return exactMatch
        } else {
            // the function will filter out all repository elements that whose initial characters do not match exactly with the search term
            return filteredDataRepository.filter((item) => {
                const item_name = item.name.toLowerCase()
                return item_name.startsWith(searchTerm) && searchTerm !== item_name
            }).slice(0, 5)
        }
    } else {
        return filteredDataRepository
    }
}

// the following function adds a filter value to the list of applied filters when it is clicked on from a filter dropdown menu
export function handleFilterDropDownClick(appliedFilters, setAppliedFilters, filterType, filterValue, setFilterValue) {
    const appliedFilters_temp = Object.assign({}, appliedFilters)
    if (appliedFilters_temp[filterType] !== null) {
        if (!appliedFilters_temp[filterType].includes(filterValue)) {
            addFilter(appliedFilters, setAppliedFilters, filterType, filterValue)
        }
    }
    setFilterValue("")
}

// the following function resets all applied filter values to default
export function removeAllFiltersButton(appliedFilters, setAppliedFilters, setIngredientValue, setCuisineValue, setCookTimeValue, setNutritionalValues) {
    function handleClick() {
        setAppliedFilters({ ingredients: [], cuisines: [], cookTime: 100000, nutritionalValues: { maxCalorie: 100000, minProtein: 0, maxCarbs: 100000, maxFat: 100000 } })
        setIngredientValue("")
        setCuisineValue("")
        setCookTimeValue("")
        setNutritionalValues({ maxCalorie: "", minProtein: "", maxFat: "", maxCarbs: "" })
    }
    if (areFiltersApplied(appliedFilters)) {
        return (<div className="remove-all-filters-button" onClick={handleClick}>Remove All Filters</div>)
    }
}