import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter as Router } from 'react-router-dom'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <GoogleOAuthProvider clientId="25629184615-f4fesuprpai06829ujri8ol4dkbtkdgv.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </Router>
);
