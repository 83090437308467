import { Link, useNavigate } from "react-router-dom"
import { logout } from "./Functions/Authentication-Functions"

function StandardNavBar(prop) {
    const navigate = useNavigate()
    if (prop.authorised) {
        return (
            <nav className="nav-bar standard">
                <div className="nav-bar-container">
                    <div className="nav-button home">
                        <Link to="/home">Home</Link>
                    </div>
                    <div className="nav-button meal-planner">
                        <Link to="/home">Meal Planner</Link>
                    </div>
                    <div className="nav-button saved-recipes">
                        <Link to="/saved-recipes">Saved Recipes</Link>
                    </div>
                    <div className="nav-button logout" onClick={() => logout(navigate, prop.setAuthorised, prop.setSavedRecipeIDs, prop.setSavedRecipes)}>Logout</div>
                </div >
            </nav>
        )
    } else {
        return (
            <nav className="nav-bar standard">
                <div className="nav-bar-container">
                    <div className="nav-button home">
                        <Link to="/home">Home</Link>
                    </div>
                    <div className="nav-button register">
                        <Link to="/register">Register</Link>
                    </div>
                    <div className="nav-button login">
                        <Link to="/login">Login</Link>
                    </div>
                </div>
            </nav>
        )
    }
}
export default StandardNavBar