// the following function handles server responses and errors
export function response_handler(response) {
    if (response.ok) {
        return response.json()
    } else if (response.status === 402) {
        throw new Error("You have exceeded your daily spoonacular allowance")
    } else if (response.status === 429) {
        throw new Error("Too many requests!")
    } else {
        throw new Error("Server Not Responding!")
    }
}

// the following function provides a template for contacting a URL via an HTTP Post request
// the body of the HTTP requests constructed by this function will always contain an apiKey property 
// the server application will always look for this apiKey in the HTTP requests it receives before it serves them  
export async function postRquest(requestURL, body) {
    if (body !== undefined){
        body.apiKey = process.env.REACT_APP_backendApiKey
    } else {
        body = {apiKey: process.env.REACT_APP_backendApiKey}
    }
    const postRequestOptions = {
        method: "POST",
        body: JSON.stringify(body),
        headers: { "Content-type": "Application/json" },
        credentials: 'include'
    }
    return fetch(process.env.REACT_APP_backEndServerURL + requestURL, postRequestOptions)
}

const getRequestOptions = {
    method: "GET",
    headers: { "Content-type": "Application/json; charset=UTF-8" }
}

// the following function constructs API calls for fetching random recipes
export async function fetchRandomRecipes(number) {
    if (process.env.REACT_APP_databaseToContact === "Spoonacular") {
        // the following API call is generated when the application database is set to Spoonacular within the environment variables
        const response = await fetch("https://api.spoonacular.com/recipes/random?" + new URLSearchParams({
            apiKey: process.env.REACT_APP_spoonacularApiKey,
            number: number
        }), getRequestOptions)
        const handledResponse = await response_handler(response)
        return handledResponse['recipes']
    }
    if (process.env.REACT_APP_databaseToContact === "TheCookBook") {
        // the following API call is generated when the application database is set to TheCookBook within the environment variables
        const response = await postRquest('/recipes/random', { number: number })
        return response_handler(response)
    }
}

// the following function constructs API calls for fetching filtered recipes
export async function fetchQueryRecipes(N_recipesToRetrieve, queryTerm, appliedFilters) {
    const nutritionalValues = appliedFilters.nutritionalValues
    if (process.env.REACT_APP_databaseToContact === "Spoonacular") {
        // the following API call is generated when the application database is set to Spoonacular within the environment variables
        console.log(appliedFilters)
        const includeIngredients = appliedFilters.ingredients.join(",")
        const cuisineOptions = appliedFilters.cuisines.join(",")
        const cookTime = appliedFilters.cookTime
        const response = await fetch("https://api.spoonacular.com/recipes/complexSearch?" + new URLSearchParams({
            apiKey: process.env.REACT_APP_spoonacularApiKey,
            number: N_recipesToRetrieve,
            query: queryTerm,
            includeIngredients: includeIngredients,
            cuisine: cuisineOptions,
            maxReadyTime: cookTime,
            minProtein: nutritionalValues.minProtein,
            maxCarbs: nutritionalValues.maxCarbs,
            maxFat: nutritionalValues.maxFat,
            maxCalories: nutritionalValues.maxCalorie,
        }), getRequestOptions)
        const fetchedRecipes = await response_handler(response)
        const recipes = []
        for (let index = 0; index < fetchedRecipes.results.length; index++) {
            const recipe = await fetchRecipeInformation(fetchedRecipes.results[index].id)
            recipes.push(recipe)
        }
        console.log("recipes: ", fetchedRecipes)
        return recipes
    }
    if (process.env.REACT_APP_databaseToContact === "TheCookBook") {
        // the following API call is generated when the application database is set to TheCookBook within the environment variables
        const response = await postRquest('/recipes/complexSearch', {
            number: N_recipesToRetrieve,
            query: queryTerm,
            includeIngredients: appliedFilters.ingredients,
            cuisine: appliedFilters.cuisines,
            maxReadyTime: appliedFilters.cookTime,
            minProtein: nutritionalValues.minProtein,
            maxCarbs: nutritionalValues.maxCarbs,
            maxFat: nutritionalValues.maxFat,
            maxCalories: nutritionalValues.maxCalorie,
        })
        return response_handler(response)
    }
}

// the following function constructs API calls for fetching recommended recipes from the server application
export async function fetchRecommendedRecipes(number) {
    const response = await postRquest('/recipes/recommended', { 
        number: number,
        apiKey: process.env.REACT_APP_backendApiKey 
    })
    return response_handler(response)
}

// the following function constructs API calls for fetching personalised filtered recipes from the server application
export async function fetchPersonalisedQueryRecipes(number, queryTerm, appliedFilters) {
    const nutritionalValues = appliedFilters.nutritionalValues
    const response = await postRquest('/recipes/personalized-complexSearch', {
        number: number,
        apiKey: process.env.REACT_APP_backendApiKey,
        query: queryTerm,
        includeIngredients: appliedFilters.ingredients,
        cuisine: appliedFilters.cuisines,
        maxReadyTime: appliedFilters.cookTime,
        minProtein: nutritionalValues.minProtein,
        maxCarbs: nutritionalValues.maxCarbs,
        maxFat: nutritionalValues.maxFat,
        maxCalories: nutritionalValues.maxCalorie,
    })
    return response_handler(response)
}

// the following function constructs API calls for fetching recipe information 
export async function fetchRecipeInformation(id) {
    if (process.env.REACT_APP_databaseToContact === "Spoonacular") {
        // the following API call is generated when the application database is set to Spoonacular within the environment variables
        const response = await fetch("https://api.spoonacular.com/recipes/" + id + "/information?" + new URLSearchParams({
            apiKey: process.env.REACT_APP_spoonacularApiKey,
            includeNutrition: false
        }), getRequestOptions)
        return response_handler(response)
    }
    if (process.env.REACT_APP_databaseToContact === "TheCookBook") {
        // the following API call is generated when the application database is set to TheCookBook within the environment variables
        const response = await postRquest("/recipes/" + id + "/information", getRequestOptions)
        return response_handler(response)
    }
}

// the following function retrieves recipe information for all recipes whose ID is present in a specified recipe ID list
export async function fetchRecipesFromIdList(recipeIdList) {
    const fetchedRecipes = []
    if (recipeIdList !== null) {
        for (let index = 0; index < recipeIdList.length; index++) {
            const recipe = await fetchRecipeInformation(recipeIdList[index])
            fetchedRecipes.push(recipe)
        }
    }
    return fetchedRecipes
}