import { useState, useEffect } from "react"
import { search } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

function SearchBar(prop) {
    const [searchTerm, setSearchTerm] = useState("")
    const [submitEvent, setSubmitEvent] = useState(null)

    // the recipe search function (defined in the RecipeSearch component) is called whenever the queryTerm state changes
    // the queryTerm state will only change when the form containing the "search by recipe" field is submitted
    useEffect(() => {
        if (submitEvent !== null){
            prop.handleRecipeSearchSubmit(submitEvent)
        }
    }, [prop.queryTerm, submitEvent])

    // the following function will execute whenever the user submits the form containing the "search by recipe" field
    function handleSubmit(event) {
        event.preventDefault()
        setSubmitEvent(event)
        // the function will set the queryTerm state
        prop.setQueryTerm(searchTerm)
    }
    
    return (
        <div className="search-bar">
            <form onSubmit={handleSubmit}>
                <input 
                    className="input-field"
                    type="text"
                    placeholder="Search by recipe"
                    value = { searchTerm }
                    onChange={(event) => {setSearchTerm(event.target.value)}}
                />
                <div className="submit-button" onClick={handleSubmit}>
                    <IonIcon className="magnify-icon" icon={search} onClick={handleSubmit}></IonIcon>
                </div>
            </form>
        </div>
    )
}
export default SearchBar