import { useEffect, useState } from "react"
import { Link, Navigate } from "react-router-dom"
import { unsaveAllButton } from './Functions/Save-Recipe-Functions'
import SearchNavBar from "./SearchNavBar"
import SaveButton from "./SaveButton"
import FilterSearchBar from "./FilterSearchBar"
import missingImage from './meal-image.png';
import Skeleton from 'react-loading-skeleton'
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { areFiltersApplied } from "./Functions/SearchFilter-Functions"

function SavedRecipes(prop) {
    const [currentPageRecipes, setCurrentPageRecipes] = useState(null)
    const [queryTerm, setQueryTerm] = useState("")
    const [appliedFilters, setAppliedFilters] = useState({ ingredients: [], cuisines: [], cookTime: 100000, nutritionalValues: { maxCalorie: 100000, minProtein: 0, maxCarbs: 100000, maxFat: 100000 } })
    const [error, setError] = useState(null)

    // the following will highlight the saved recipes button from the navigation bar when this component is mounted
    useEffect(() => {
        const navButton = document.querySelector('.nav-button.saved-recipes')
        if (navButton !== null && navButton !== undefined) {
            navButton.style.backgroundColor = '#aaa9c1'
        }
    }, [])

    // the following will synchronise the recipes in the saved recipes page with the savedRecipes state whenever
    // the savedRecipes state changes 
    useEffect(() => {
        setCurrentPageRecipes(prop.savedRecipes)
    }, [prop.savedRecipes])

    // the following funciton will filter the visible recipes on the saved recipe page
    async function handleRecipeSearchSubmit(submitEvent) {
        submitEvent.preventDefault()
        // the function checks to see if there are any filter values applied 
        if (!areFiltersApplied(appliedFilters) && queryTerm === "") {
            // the function will present all saved recipes in the saved recipes page
            setCurrentPageRecipes(prop.savedRecipes)
        } else {
            // the function filters the saved recipes according to the applied filters and presents them on the saved recipes page
            setCurrentPageRecipes(savedRecipesfilter(appliedFilters, prop.savedRecipes))
        }
    }

    // the following function filters the saved recipes according to the applied filter values
    function savedRecipesfilter(appliedFilters, savedRecipes) {
        // the differnet filter values are extracted from the appliedFilters object
        const cuisineFilters = appliedFilters["cuisines"]
        const ingredientFilters = appliedFilters["ingredients"]
        const cookTimeFilter = appliedFilters["cookTime"]
        const nutritionalValuesFilter = appliedFilters["nutritionalValues"]
        const queryTermsArray = queryTerm.toLowerCase().split(" ")
        let filteredSavedRecipes = Object.assign([], savedRecipes)
        // the funciton creates a list of all saved recipes
        filteredSavedRecipes = filteredSavedRecipes.filter((element) => { return element.readyInMinutes <= cookTimeFilter })
        // the function filters out all recipes that do not belong to any of the specified cuisine type filter values 
        if (cuisineFilters.length !== 0) {
            filteredSavedRecipes = filteredSavedRecipes.filter((element) => {
                return cuisineFilters.some(item => element.cuisines.includes(item));
            })
        }
        // the function filters out all recipes that do not use all the specified ingredient filter values
        if (ingredientFilters.length !== 0) {
            filteredSavedRecipes = filteredSavedRecipes.filter(recipe => {
                const ingredientNames = recipe.extendedIngredients.map(ingredient => ingredient.name);
                return ingredientFilters.every(filteredIngredient => ingredientNames.includes(filteredIngredient));
            })
        }
        // the function filters out all recipes whose title does not include the search term 
        filteredSavedRecipes = filteredSavedRecipes.filter(recipe => {
            let booleanToken = false
            const recipeTitleTermsArray = recipe.title.toLowerCase().split(" ")
            for (let j = 0; j < recipeTitleTermsArray.length; j++) {
                for (let i = 0; i < queryTermsArray.length; i++) {
                    booleanToken = booleanToken || recipeTitleTermsArray[j].startsWith(queryTermsArray[i])
                }
            }
            return booleanToken
        })
        // the function filters out all recipes that do not meet all the nuritional value filters
        const pattern = /-?\d+(?:\.\d+)?/g;
        filteredSavedRecipes = filteredSavedRecipes.filter(recipe => 
            recipe.calories.match(pattern)[0] <= nutritionalValuesFilter.maxCalorie 
            && recipe.nutritionalValues.Protein.match(pattern)[0] >= nutritionalValuesFilter.minProtein
            && recipe.nutritionalValues.Carbohydrates.match(pattern)[0] <= nutritionalValuesFilter.maxCarbs    
            && recipe.nutritionalValues.Fat.match(pattern)[0] <= nutritionalValuesFilter.maxFat    
        )
        return filteredSavedRecipes
    }

    function SearchResults() {
        // this function will render a recipe image if one exists
        // this function will render a place holder image if a recipe image does not exist 
        function renderImage(element) {
            if (element.image !== undefined) {
                return (<img className="image" src={element.image} alt="Not found" />)
            } else {
                return (<img className="image" src={missingImage} alt="Not found" />)
            }
        }

        if (prop.savedRecipesIsPending === false) {
            if (currentPageRecipes !== null && currentPageRecipes !== undefined) {
                if (currentPageRecipes.length !== 0) {
                    return (
                        <div>
                            <div className="search-results">
                                {currentPageRecipes.map((element) =>
                                    <div className="search-result" key={element.id}>
                                        <Link to={"/recipes/" + element.id}>
                                            {renderImage(element)}
                                            <div className="text-container">
                                                <div className="title">{element.title}</div>
                                                <div className="cook-time">Cook Time: {element.readyInMinutes}</div>
                                                <div className="ingredient-count">Ingredient Count: {element.extendedIngredients.length}</div>
                                            </div>
                                            <SaveButton
                                                useIsAuthenticated={prop.authorised}
                                                savedRecipeIDs={prop.savedRecipeIDs}
                                                setSavedRecipeIDs={prop.setSavedRecipeIDs}
                                                savedRecipes={prop.savedRecipes}
                                                setSavedRecipes={prop.setSavedRecipes}
                                                recipe_id={element.id}
                                            />
                                        </Link>
                                    </div>
                                )}
                            </div >
                            {unsaveAllButton(prop.authorised, prop.setSavedRecipeIDs, prop.setSavedRecipes)}
                        </div>

                    )
                } else {
                    return (
                        <div className="no-recipes-message">There are no saved recipes!</div>
                    )
                }
            }
        } else {
            return (
                <div className="search-results">
                    {[...Array(prop.savedRecipeIDs.length)].map((element, index) =>
                        <SkeletonTheme key={index} baseColor='#6A6767' highlightColor='#525252'>
                            <div className="temporary-search-result">
                                <div className="image"><Skeleton></Skeleton></div>
                                <div className="text-container">
                                    <div className="title"><Skeleton></Skeleton></div>
                                    <div className="cook-time"><Skeleton></Skeleton></div>
                                    <div className="ingredient-count"><Skeleton></Skeleton></div>
                                </div>
                            </div>
                        </SkeletonTheme>
                    )}
                </div>
            )
        }
    }

    if (!prop.authorised) {
        return (<Navigate to="/home" />)
    }

    return (
        <div className="saved-recipes">
            <SearchNavBar 
                queryTerm={queryTerm} 
                setQueryTerm={setQueryTerm} 
                handleRecipeSearchSubmit={handleRecipeSearchSubmit} 
                authorised={prop.authorised} 
                setSavedRecipeIDs={prop.setSavedRecipeIDs}
                setUserIsAuthenticated={prop.setUserIsAuthenticated}
                setSavedRecipes={prop.setSavedRecipes}
            />
            <FilterSearchBar 
                appliedFilters={appliedFilters} 
                setAppliedFilters={setAppliedFilters} 
                handleSubmit={handleRecipeSearchSubmit}
            />
            {error && <div>{error}</div>}
            <div className="recipe-results-contianer">
                {SearchResults()}
            </div>
        </div>
    )
}
export default SavedRecipes