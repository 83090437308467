import { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { loadUser } from './Functions/Load-User-Functions';
import { authenticateUser } from './Functions/Authentication-Functions';
import Registration from './Registration'
import Login from './Login'
import RecipeDetails from './RecipeDetails';
import SavedRecipes from './SavedRecipes';
import BottomNavBar from './BottomNavBar';
import RecipeSearch from './RecipeSearch';
import './CSS/App.css';
import './CSS/NavigationBar.css'
import './CSS/ApplyFiltersBar.css'
import './CSS/SavedRecipes.css'
import './CSS/RecipeDetails.css'
import './CSS/Login.css'
import './CSS/Registration.css'
import './CSS/SearchResults.css'
import './CSS/FilterValuesBar.css'
import './CSS/BottomNavBar.css'

function App() {
  const [userIsAuthenticated, setUserIsAuthenticated] = useState(false)
  const [savedRecipeIDs, setSavedRecipeIDs] = useState([])
  const [savedRecipes, setSavedRecipes] = useState([])
  const [savedRecipesIsPending, setSavedRecipesIsPending] = useState(true)
  const [authorisationIsPending, setAuthorisationIsPending] = useState(true)
  const location = useLocation()
  const navigate = useNavigate()

  // the following hook sets the position styling of the bottom navigation bar to fixed at initial rendering
  useEffect(() => {
    if (location.pathname !== "/home" && location.pathname !== "/" && location.pathname !== "/saved-recipes") {
      const navbar = document.querySelector('.App .bottom-nav-bar');
      navbar.style.position = 'fixed';
    }
  }, [location])

  // the following hook loads the user (retrieves the recipes saved by the user) from the server whenever the user is authenticated
  useEffect(() => {
    if (userIsAuthenticated === true) {
      loadUser(setSavedRecipeIDs, setSavedRecipes, setSavedRecipesIsPending)
      navigate('/')
    }
  }, [userIsAuthenticated])

  // the following hook sets the position styling of the bottom navigation bar to relative if the height of the element containing
  // the page content starts to occupy more than 85% of the browser window height
  // this ensures that the bottom navigation bar always appears after the content and not over it
  useEffect(() => {
    // an observer object is defined to observe the resizing of a given DOM element
    // the following body of code will run whenever a resizing is observered
    var ro = new ResizeObserver(entries => {
      // the windowHeight variable refers to the the window height
      const windowHeight = window.innerHeight;
      for (let entry of entries) {
        const navbar = document.querySelector('.App .bottom-nav-bar');
        // this contentHeight variable will be set to refer to the height of the element containing the page content
        const contentHeight = entry.contentRect.height
        if (contentHeight < 0.85 * windowHeight) {
          navbar.style.position = 'fixed';
        } else {
          navbar.style.position = 'relative';
        }
      }
    })
    // the element DOM containing the page content is assigned to the observer object
    ro.observe(document.querySelector(".App .content:first-child"));
  }, [])

  // the client application attempts to authenticate the user at the server when it is first rendered
  useEffect(() => {
    authenticateUser(setUserIsAuthenticated, setAuthorisationIsPending).catch(error => { })
  }, [])

  return (
    <div>
      {userIsAuthenticated !== null &&
        <div className="App">
          <div className="content">
            <Routes>
              <Route path="/" element={
                <RecipeSearch
                  authorised={userIsAuthenticated}
                  setUserIsAuthenticated={setUserIsAuthenticated}
                  setSavedRecipeIDs={setSavedRecipeIDs}
                  savedRecipeIDs={savedRecipeIDs}
                  setSavedRecipes={setSavedRecipes}
                  savedRecipes={savedRecipes}
                  authorisationIsPending={authorisationIsPending}
                />
              } />
              <Route path="/home" element={
                <RecipeSearch
                  authorised={userIsAuthenticated}
                  setSavedRecipeIDs={setSavedRecipeIDs}
                  setUserIsAuthenticated={setUserIsAuthenticated}
                  savedRecipeIDs={savedRecipeIDs}
                  setSavedRecipes={setSavedRecipes}
                  savedRecipes={savedRecipes}
                  authorisationIsPending={authorisationIsPending}
                />
              } />
              <Route path="/register" element={
                <Registration
                  authorised={userIsAuthenticated}
                  setAuthorised={setUserIsAuthenticated}
                  setSavedRecipeIDs={setSavedRecipeIDs}
                  savedRecipeIDs={savedRecipeIDs}
                  setAuthorisationIsPending={setAuthorisationIsPending}
                  setSavedRecipes={setSavedRecipes}
                />
              } />
              <Route path="/login" element={
                <Login
                  authorised={userIsAuthenticated}
                  setAuthorised={setUserIsAuthenticated}
                  setSavedRecipeIDs={setSavedRecipeIDs}
                  savedRecipeIDs={savedRecipeIDs}
                  setAuthorisationIsPending={setAuthorisationIsPending}
                  setSavedRecipes={setSavedRecipes}
                />
              } />
              <Route path="/saved-recipes" element={
                <SavedRecipes
                  authorised={userIsAuthenticated}
                  setUserIsAuthenticated={setUserIsAuthenticated}
                  setSavedRecipeIDs={setSavedRecipeIDs}
                  savedRecipeIDs={savedRecipeIDs}
                  setSavedRecipes={setSavedRecipes}
                  savedRecipes={savedRecipes}
                  savedRecipesIsPending={savedRecipesIsPending}
                />
              } />
              <Route path="/recipes/:id" element={
                <RecipeDetails
                  authorised={userIsAuthenticated}
                  setSavedRecipeIDs={setSavedRecipeIDs}
                  savedRecipeIDs={savedRecipeIDs}
                  setSavedRecipes={setSavedRecipes}
                  savedRecipes={savedRecipes}
                />
              } />
            </Routes>
          </div>
          <BottomNavBar></BottomNavBar>
        </div>
      }
    </div>
  )
}
export default App;
