import { useEffect, useRef } from 'react'
import _ from 'lodash'

function useOutsideClick(callbackFunction, protectedElementsList, filterIsVisible) {
  const originalElementRef = useRef(null)

  useEffect(() => {
    function handleClick(event) {
      if (
        filterIsVisible
        && originalElementRef.current
        && !originalElementRef.current.contains(event.target)
        && event.target.className !== originalElementRef.current.className
        && event.target.parentElement !== originalElementRef.current
      ) {
        if (Object.keys(protectedElementsList.ingredients).length === 0 && Object.keys(protectedElementsList.cuisines).length === 0) {
          callbackFunction()
        } else {
          const x = Object.assign([], protectedElementsList.ingredients)
          const y = Object.assign([], protectedElementsList.cuisines)
          const combinedProtectedElements = _.merge(x, y)
          let protectedClick = false
          for (let protectedElement in combinedProtectedElements) {
            const protectedElementRef = combinedProtectedElements[protectedElement]
            if (protectedElementRef.current !== null) {
              if (event.target.className === protectedElementRef.current.className
                || event.target.parentElement === protectedElementRef.current
                || Array.from(protectedElementRef.current.querySelectorAll("*") ?? []).some((el) => el.contains(event.target))
                || Array.from(protectedElementRef.current.querySelectorAll("*") ?? []).some((el) => el.contains(event.target))) {
                protectedClick = true
              }
            }
          }
          if (!protectedClick) {
            callbackFunction()
          }
        }
      }
    }
    document.addEventListener('click', handleClick, true);
    return () => { document.removeEventListener('click', handleClick, true) }

  }, [originalElementRef, protectedElementsList, filterIsVisible, callbackFunction]);

  return originalElementRef
}
export default useOutsideClick