import { handleFilterVisibility, handleFilterDropDownClick, filterCounter, autocomplete, removeAllFiltersButton, removeFilter, addFilter, isValidInput, handleNutritionalValueChange } from "./Functions/SearchFilter-Functions"
import { useState, useEffect, createRef } from "react"
import { ingredients } from './Data Sets/Ingredients'
import { cuisines } from './Data Sets/Cuisines'
import { chevronDown, chevronUp } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import useOutsideClick from "./Custom Hooks/useOutsideClick"

function FilterSearchBar({ appliedFilters, setAppliedFilters }) {
    const [ingredientValue, setIngredientValue] = useState("")
    const [cuisineValue, setCuisineValue] = useState("")
    const [cookTimeValue, setCookTimeValue] = useState("")
    const [nutritionalValues, setNutritionalValues] = useState({ maxCalorie: "", minProtein: "", maxFat: "", maxCarbs: "" })
    const [filterVisiblity, setFilterVisiblity] = useState({ ingredients: false, cuisines: false, cookTime: false, nutritionalValues: false })
    const [protectedElementsList, setProtectedElementsList] = useState({ ingredients: [], cuisines: [] })
    const ingredientFilterRef = useOutsideClick(() => { handleFilterVisibility(filterVisiblity, setFilterVisiblity, { "ingredients": false, "cuisines": false }) }, protectedElementsList, filterVisiblity["ingredients"])
    const cuisineFilterRef = useOutsideClick(() => { handleFilterVisibility(filterVisiblity, setFilterVisiblity, { "ingredients": false, "cuisines": false }) }, protectedElementsList, filterVisiblity["cuisines"])

    // the following hook resets the cook time filter whenever it is empty or set to 0
    useEffect(() => {
        if (cookTimeValue === "" || cookTimeValue === 0) {
            const temp = Object.assign({}, appliedFilters)
            temp.cookTime = 100000
            setAppliedFilters(temp)
        }
    }, [cookTimeValue])

    // the following hook creates a list to keep track of every filter value which is to be protected from the useOutsideClick hook
    useEffect(() => {
        for (let ingredientFilter of appliedFilters.ingredients) {
            if (!protectedElementsList.hasOwnProperty(ingredientFilter)) {
                // the function creates an object for every ingredient filter that is currently applied
                const protectedElementsList_temp = Object.assign({}, protectedElementsList)
                // the object will contain a single property that shares its name with the ingredient filter value
                // the property will point to a ref object which will later be set to point to the DOM element 
                // that is to be protected from the useOutsideClick hook
                protectedElementsList_temp.ingredients[ingredientFilter] = createRef(null)
                setProtectedElementsList(protectedElementsList_temp)
            }
        }
        for (let protectedElement in protectedElementsList.ingredients) {
            if (!appliedFilters.ingredients.includes(protectedElement)) {
                // the function removes filter objects from the list of protected elements when those filter values are removed
                const protectedElementsList_temp = Object.assign({}, protectedElementsList)
                delete protectedElementsList_temp.ingredients[protectedElement]
                setProtectedElementsList(protectedElementsList_temp)
            }
        }
        for (let cuisineFilter of appliedFilters.cuisines) {
            if (!protectedElementsList.hasOwnProperty(cuisineFilter)) {
                // the function creates an object for every cuisine filter that is currently applied
                const protectedElementsList_temp = Object.assign({}, protectedElementsList)
                // the object will contain a single property that shares its name with the cuisine filter value
                // the property will point to a ref object which will later be set to point to the DOM element 
                // that is to be protected from the useOutsideClick hook
                protectedElementsList_temp.cuisines[cuisineFilter] = createRef(null)
                setProtectedElementsList(protectedElementsList_temp)
            }
        }
        for (let protectedElement in protectedElementsList.cuisines) {
            if (!appliedFilters.cuisines.includes(protectedElement)) {
                // the function removes filter objects from the list of protected elements when those filter values are removed
                const protectedElementsList_temp = Object.assign({}, protectedElementsList)
                delete protectedElementsList_temp.cuisines[protectedElement]
                setProtectedElementsList(protectedElementsList_temp)
            }
        }
    }, [appliedFilters])

    // the following function renders the filtered values bar
    function FilteredValuesBar() {
        if (filterVisiblity["ingredients"] === true && appliedFilters.ingredients.length !== 0) {
            return (
                // the ingredient filter list is iterated and filter values are rendered one by one in filter bar
                <div className="filtered-values-bar">{appliedFilters.ingredients.map(filterValue => 
                    // the DOM of the elements containing the different ingredient filter values are then assigned to 
                    // accordingly to the list protected elements from the useOutsideClick hook 
                    <div className="filter-value" key={filterValue} ref={protectedElementsList.ingredients[[filterValue]]}>
                        <div className="value">{filterValue}</div>
                        <div className="cross-button" onClick={() => { removeFilter(appliedFilters, setAppliedFilters, "ingredients", filterValue) }}></div>
                    </div>
                )
                }</div>
            )
        } else if (filterVisiblity["cuisines"] === true && appliedFilters.cuisines.length !== 0) {
            return (
                // the cuisine filter list is iterated and filter values are rendered one by one in filter bar
                <div className="filtered-values-bar">{appliedFilters.cuisines.map(filterValue =>
                    // the DOM of the elements containing the different cuisine filter values are then assigned to 
                    // accordingly to the list protected elements from the useOutsideClick hook 
                    <div className="filter-value" key={filterValue} ref={protectedElementsList.cuisines[[filterValue]]}>
                        <div className="value">{filterValue}</div>
                        <div className="cross-button" onClick={() => { removeFilter(appliedFilters, setAppliedFilters, "cuisines", filterValue) }}></div>
                    </div>
                )
                }</div>
            )
        }
    }

    // the following function adds a filter value when it is submitted from a filter dropdown menu
    function handleSubmitFromDropDown(submitEvent) {
        submitEvent.preventDefault()
        if (filterVisiblity["ingredients"] === true) {
            // the function checks to see if the current search term in the ingredient filter is valid (is recognised by the system) 
            const validityObject = isValidInput(ingredientValue, ingredients)
            if (validityObject[0] === true) {
                const matchingFilterValue = validityObject[1][0].name
                if (!appliedFilters.ingredients.includes(matchingFilterValue)) {
                    // the filter value is applied to the recipe search if the search term is valid
                    addFilter(appliedFilters, setAppliedFilters, "ingredients", matchingFilterValue)
                }
            }
        }
        if (filterVisiblity["cuisines"] === true) {
            // the function checks to see if the current search term in the cuisine filter is valid (is recognised by the system) 
            const validityObject = isValidInput(cuisineValue, cuisines)
            if (validityObject[0] === true) {
                const matchingFilterValue = validityObject[1][0].name
                if (!appliedFilters.cuisines.includes(matchingFilterValue)) {
                    // the filter value is applied to the recipe search if the search term is valid
                    addFilter(appliedFilters, setAppliedFilters, "cuisines", matchingFilterValue)
                }
            }
        }
    }

    return (
        <div>
            <div className="search-filter-bar">
                {FilteredValuesBar()}
                <div className="apply-filters-bar">
                    <form onSubmit={handleSubmitFromDropDown}>
                        <div className="ingredient-filter-container"
                            onClick={() => {
                                // the ingredients filter option is set to visible when the ingredient filter button is clicked
                                handleFilterVisibility(filterVisiblity, setFilterVisiblity, { "ingredients": true, "cuisines": false, "cookTime": false })
                            }}
                            ref={ingredientFilterRef}
                        >
                            {filterVisiblity["ingredients"] === false &&
                                <div className="filter-prompt-button">
                                    <div className="value">Ingredients</div>
                                    {filterCounter(appliedFilters, "ingredients")}
                                </div>
                            }
                            {filterVisiblity["ingredients"] === true &&
                                <div className="filter">
                                    <input
                                        autoFocus
                                        className="input-field"
                                        type="text"
                                        placeholder="Search by ingredients"
                                        value={ingredientValue}
                                        onChange={(event) => { setIngredientValue(event.target.value) }}
                                    />
                                    <div className="dropdown-container">
                                        <div className="dropdown">
                                            {autocomplete(ingredientValue, ingredients, appliedFilters, "ingredients").map((item) =>
                                                <div
                                                    // clicking on the ingredient filter value from the dropdown menu 
                                                    // will apply the filter value to the recipe search
                                                    onClick={() => { handleFilterDropDownClick(appliedFilters, setAppliedFilters, "ingredients", item.name, setIngredientValue) }}
                                                    className="dropdown-row"
                                                    key={item.name}
                                                >
                                                    {item.name}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="cuisine-filter-container"
                            onClick={() => {
                                // the cuisines filter option is set to visible when the cuisine filter button is clicked
                                handleFilterVisibility(filterVisiblity, setFilterVisiblity, { "cuisines": true, "ingredients": false, "cookTime": false })
                            }}
                            ref={cuisineFilterRef}
                        >
                            {filterVisiblity["cuisines"] === false &&
                                <div className="filter-prompt-button">
                                    <div className="value">Cuisines</div>
                                    {filterCounter(appliedFilters, "cuisines")}
                                </div>
                            }
                            {filterVisiblity["cuisines"] === true &&
                                <div className="filter">
                                    <input
                                        autoFocus
                                        className="input-field"
                                        type="text"
                                        placeholder="Search by cuisine"
                                        value={cuisineValue}
                                        onChange={(event) => { setCuisineValue(event.target.value) }}
                                    />
                                    <div className="dropdown-container">
                                        <div className="dropdown">
                                            {autocomplete(cuisineValue, cuisines, appliedFilters, "cuisines").map((item) =>
                                                <div
                                                    // clicking on the cuisine filter value from the dropdown menu 
                                                    // will apply the filter value to the recipe search
                                                    onClick={() => { handleFilterDropDownClick(appliedFilters, setAppliedFilters, "cuisines", item.name, setCuisineValue) }}
                                                    className="dropdown-row"
                                                    key={item.name}
                                                >
                                                    {item.name}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="flex-break"></div>
                        <div className="cook-time-filter-container"
                            onClick={() => {
                                // the cook time filter option is set to visible when the cook time filter button is clicked
                                handleFilterVisibility(filterVisiblity, setFilterVisiblity, { "cookTime": true, "cuisines": false, "ingredients": false })
                            }}
                        >
                            {filterVisiblity["cookTime"] === false &&
                                <div className="filter-prompt-button">
                                    <div className="value">Cook Time</div>
                                </div>}
                            {filterVisiblity["cookTime"] === true &&
                                <div className="filter">
                                    <div className="title">Cook Time</div>
                                    <input
                                        className="input-field"
                                        autoFocus
                                        type="number"
                                        value={cookTimeValue}
                                        onChange={(event) => {
                                            const newCookTimeValue = event.target.value
                                            setCookTimeValue(newCookTimeValue)
                                            // the cook time filter is reset when invalid values are entered 
                                            if (newCookTimeValue !== "" && newCookTimeValue > 0 && newCookTimeValue !== NaN) {
                                                addFilter(appliedFilters, setAppliedFilters, "cookTime", parseInt(newCookTimeValue))
                                            }
                                        }}
                                    />
                                    {/* the cook time filter is reset when the cross button is clicked */}
                                    <div className="cross-button" onClick={(event) => {
                                        event.stopPropagation()
                                        handleFilterVisibility(filterVisiblity, setFilterVisiblity, { "cookTime": false, "cuisines": false, "ingredients": false })
                                        addFilter(appliedFilters, setAppliedFilters, "cookTime", 100000)
                                        setCookTimeValue("")
                                    }}
                                    ></div>
                                </div>
                            }
                        </div>
                        {filterVisiblity["nutritionalValues"] === false &&
                            <div
                                className="nutritional-values-prompt-button"
                                onClick={() => {
                                    if (filterVisiblity["nutritionalValues"]) {
                                        // the nutritional values filter becomes visisble when the nutritional values button is clicked
                                        handleFilterVisibility(filterVisiblity, setFilterVisiblity, { "nutritionalValues": false, "ingredients": false, "cuisines": false, "cookTime": false })
                                    } else {
                                        // the nutritional values filter becomes invisisble when the nutritional values button is clicked again
                                        handleFilterVisibility(filterVisiblity, setFilterVisiblity, { "nutritionalValues": true, "ingredients": false, "cuisines": false, "cookTime": false })
                                    }
                                }}
                            >
                                <div className="value">Nutritional Values</div>
                                {filterCounter(appliedFilters, "nutritionalValues")}
                                <IonIcon className="chevron-down" icon={chevronDown}></IonIcon>
                            </div>
                        }
                        {filterVisiblity["nutritionalValues"] === true &&
                            <div
                                className="nutritional-values-prompt-button active"
                                onClick={() => {
                                    if (filterVisiblity["nutritionalValues"]) {
                                        // the nutritional values filter becomes visisble when the nutritional values button is clicked
                                        handleFilterVisibility(filterVisiblity, setFilterVisiblity, { "nutritionalValues": false, "ingredients": false, "cuisines": false, "cookTime": false })
                                    } else {
                                        // the nutritional values filter becomes invisisble when the nutritional values button is clicked again
                                        handleFilterVisibility(filterVisiblity, setFilterVisiblity, { "nutritionalValues": true, "ingredients": false, "cuisines": false, "cookTime": false })
                                    }
                                }}
                            >
                                <div className="value">Nutritional Values</div>
                                <IonIcon className="chevron-up" icon={chevronUp}></IonIcon>
                            </div>
                        }

                    </form>
                    {removeAllFiltersButton(appliedFilters, setAppliedFilters, setIngredientValue, setCuisineValue, setCookTimeValue, setNutritionalValues)}
                </div>
            </div >
            {filterVisiblity["nutritionalValues"] === true &&
                <div className="nutritional-values-filter-container">
                    <form>
                        <div className="filter calorie">
                            <div className="title">Max Calorie</div>
                            <input
                                type="number"
                                className="input-field"
                                value={nutritionalValues.maxCalorie}
                                onChange={(event) => {
                                    const newMaxCalorie = event.target.value
                                    handleNutritionalValueChange(nutritionalValues, setNutritionalValues, appliedFilters, setAppliedFilters, "maxCalorie", newMaxCalorie)
                                }}
                            />
                        </div>
                        <div className="filter protein">
                            <div className="title">Min Protein</div>
                            <input
                                type="number"
                                className="input-field"
                                value={nutritionalValues.minProtein}
                                onChange={(event) => {
                                    const newMinProtein = event.target.value
                                    handleNutritionalValueChange(nutritionalValues, setNutritionalValues, appliedFilters, setAppliedFilters, "minProtein", newMinProtein)
                                }}
                            />
                        </div>
                        <div className="filter carb">
                            <div className="title">Max Carbs</div>
                            <input
                                type="number"
                                className="input-field"
                                value={nutritionalValues.maxCarbs}
                                onChange={(event) => {
                                    const newMaxCarbs = event.target.value
                                    handleNutritionalValueChange(nutritionalValues, setNutritionalValues, appliedFilters, setAppliedFilters, "maxCarbs", newMaxCarbs)
                                }}
                            />
                        </div>
                        <div className="filter fat">
                            <div className="title">Max Fat</div>
                            <input
                                type="number"
                                className="input-field"
                                value={nutritionalValues.maxFat}
                                onChange={(event) => {
                                    const newMaxFat = event.target.value
                                    handleNutritionalValueChange(nutritionalValues, setNutritionalValues, appliedFilters, setAppliedFilters, "maxFat", newMaxFat)
                                }}
                            />
                        </div>
                    </form>
                </div>
            }
        </div >
    )
}
export default FilterSearchBar