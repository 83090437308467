
import { postRquest } from './Fetch-Functions';
import { fetchRecipesFromIdList } from './Fetch-Functions';

// the following function handles a click on the save recipe button
export async function handleSaveButtonClick(useIsAuthenticated, savedRecipeIDs, setSavedRecipeIDs, savedRecipes, setSavedRecipes, recipe_id) {
    if (useIsAuthenticated) {
        const icon = document.getElementById(recipe_id)
        if (savedRecipeIDs !== null) {
            // if the recipe ID is present in the list of saved recipe IDs
            if (savedRecipeIDs.includes(recipe_id)) {
                // the server application is contacted to remove the saved recipe ID
                removeSavedRecipe(savedRecipeIDs, setSavedRecipeIDs, savedRecipes, setSavedRecipes, recipe_id)
                // the save recipe button is unhighlighted
                icon.classList.remove('active')
            } else {
                // the server application is contacted to add the saved recipe ID
                addSavedRecipe(savedRecipeIDs, setSavedRecipeIDs, savedRecipes, setSavedRecipes, recipe_id)
                // the save recipe button is highlighted
                icon.classList.add('active')
            }
        } else {
            addSavedRecipe(savedRecipeIDs, setSavedRecipeIDs, savedRecipes, setSavedRecipes, recipe_id)
            icon.classList.add('active')
        }
    } else {
        alert("You must sign in first")
    }
}

// the following function sends a request to the server to remove a given saved recipe ID  
function removeSavedRecipe(savedRecipeIDs, setSavedRecipeIDs, savedRecipes, setSavedRecipes, recipe_id) {
    // the function removes the saved recipe ID from the savedRecipeIDs state and the savedRecipes state
    const temp_savedRecipeIDs = Object.assign([], savedRecipeIDs)
    const temp_savedRecipes = Object.assign([], savedRecipes)
    const updatedSavedRecipeIDs = temp_savedRecipeIDs.filter((element) => { return element !== recipe_id })
    const updatedSavedRecipes = temp_savedRecipes.filter((element) => { return element.id !== recipe_id })
    setSavedRecipeIDs(updatedSavedRecipeIDs)
    setSavedRecipes(updatedSavedRecipes)
    // the client sends an HTTP post request to remove the recipe ID on the server side
    postRquest("/remove-saved-recipe", { "id": recipe_id })
}

// the following function sends a request to the server to add a given saved recipe ID  
async function addSavedRecipe(savedRecipeIDs, setSavedRecipeIDs, savedRecipes, setSavedRecipes, recipe_id) {
    // the function adds the saved recipe ID to the savedRecipeIDs state and the savedRecipes state
    const temp_savedRecipeIDs = Object.assign([], savedRecipeIDs)
    const temp_savedRecipes = Object.assign([], savedRecipes)
    temp_savedRecipeIDs.push(recipe_id)
    const newRecipe = await fetchRecipesFromIdList([recipe_id])
    temp_savedRecipes.push(newRecipe[0])
    setSavedRecipeIDs(temp_savedRecipeIDs)
    setSavedRecipes(temp_savedRecipes)
    // the client sends an HTTP post request to add the recipe ID on the server side
    postRquest("/save-recipe", { "id": recipe_id })
}

// the following function sends a request to the server to remove all saved recipe IDs that have been recorded against the user
export function unsaveAllButton(savedRecipeIDs, setSavedRecipeIDs, setSavedRecipes) {
    async function handleClick() {
        const userConfirmation = window.confirm("Are you sure you want to remove all saved recipes?")
        if (userConfirmation) {
            if (savedRecipeIDs !== null) {
                // the client sends an HTTP post request to remove all recipe IDs on the server side
                postRquest("/remove-all-saved-recipes")
                // the function removes resets the savedRecipeIDs state and the savedRecipes state
                setSavedRecipeIDs([])
                setSavedRecipes([])
            }
        }
    }
    if (savedRecipeIDs.length !== 0) {
        return (<div className="unsave-all-recipes" onClick={handleClick}>Unsave All</div>)
    }
}
